import ThemeModel from "../models/themeModel";

const ThemeData = [
    new ThemeModel("Artificial Intelligence/ Machine Learning"),
    new ThemeModel("Big Data/ Data Analysis/Data Science"),
    new ThemeModel("Internet of Things/ Internet of Everything's"),
    new ThemeModel("Blockchain"),
    new ThemeModel("Data Science"),
];

export default ThemeData
