import "./footer.css";

const Footer = () => {
  return (
    <footer className="footer py-2 bg-glassy">
      <div className="container">
        <div className="row gap-y align-items-center">
          <div className="col-md-6 text-center text-md-left">
            <img
              className={"logo-footer mt-5"}
              src={"assets/img/logo/open_logo_black.svg"}
              alt="logo-left"
            />
          </div>

          <div className="col-md-6 text-center text-md-right">
            <a
              href={"https://inchtechs.com/"}
              target={"_blank"}
              rel="noreferrer"
            >
              <img
                className={"logo-footer mt-5"}
                src={"assets/img/logo/InchTech-default.png"}
                alt="logo-right"
              />
            </a>
          </div>

          <div className="col-12 text-center">
            <br />
            <br />
            <br />
            <br />
            <small className={"font-weight-bold"}>
              ©2021{" "}
              <a
                href={"https://inchtechs.com/"}
                target={"_blank"}
                rel="noreferrer"
              >
                INCHTECH'S
              </a>
              , All rights reserved.
            </small>
          </div>
        </div>
      </div>
    </footer>
  );
};
export default Footer;
