import { useState } from "react";

const useInput = (validateValue: any) => {
  const [enteredValue, setEnteredValue] = useState<any>({
    fName: "",
    lName: "",
    portable: "",
    email: "",
    school: "",
    speciality: "",
  });

  const valueIsValid = validateValue(
    enteredValue.fName &&
      enteredValue.lName &&
      enteredValue.portable &&
      enteredValue.email &&
      enteredValue.school &&
      enteredValue.speciality
  );

  const valueChangeHandler = (e: any) => {
    const { name, value } = e.target;
    setEnteredValue((preValue: any) => {
      if (name === "fName") {
        return {
          fName: value,
          lName: preValue.lName,
          portable: preValue.portable,
          email: preValue.email,
          school: preValue.school,
          speciality: preValue.speciality,
        };
      } else if (name === "lName") {
        return {
          fName: preValue.fName,
          lName: value,
          portable: preValue.portable,
          email: preValue.email,
          school: preValue.school,
          speciality: preValue.speciality,
        };
      } else if (name === "portable") {
        return {
          fName: preValue.fName,
          lName: preValue.lName,
          portable: value,
          email: preValue.email,
          school: preValue.school,
          speciality: preValue.speciality,
        };
      } else if (name === "email") {
        return {
          fName: preValue.fName,
          lName: preValue.lName,
          portable: preValue.portable,
          email: value,
          school: preValue.school,
          speciality: preValue.speciality,
        };
      } else if (name === "school") {
        return {
          fName: preValue.fName,
          lName: preValue.lName,
          portable: preValue.portable,
          email: preValue.email,
          school: value,
          speciality: preValue.speciality,
        };
      } else if (name === "speciality") {
        return {
          fName: preValue.fName,
          lName: preValue.lName,
          portable: preValue.portable,
          email: preValue.email,
          school: preValue.school,
          speciality: value,
        };
      }
    });
  };

  const reset = () => {
    setEnteredValue({
      fName: "",
      lName: "",
      portable: "",
      email: "",
      school: "",
      speciality: "",
    });
  };

  return {
    value: enteredValue,
    isValid: valueIsValid,
    valueChangeHandler,
    reset,
  };
};

export { useInput };
