import FeedbackCard from "../components/feedbackCard";
import feedbackInfo from "../components/feedbackInfo";

const Feedback = () => {
  return (
    <div className={"container"}>
      <header className="section-header text-left ml-1">
        <h4 className="text-danger letter-spacing-0 font-size-15 font-weight-bold">
          Testimonial
        </h4>
        <h1 className={"font-weight-bold"}>User Feedback</h1>
      </header>

      <FeedbackCard feedback={feedbackInfo} />
    </div>
  );
};

export default Feedback;
