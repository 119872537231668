import {useCallback, useState} from "react";
import axios from "axios";

const useHttp = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    const sendRequest = useCallback(async(config:any, resRequest: any) => {
        setIsLoading(true);
        setError(null);
        try{
            // @ts-ignore
            const response = await axios({
                method: config.method ? config.method : 'GET',
                url: config.url,
                headers: config.headers ? config.headers : {},
                data: config.data ? JSON.stringify(config.data) : null
            });
            if (!response.statusText){
                new Error('Request failed !!')
            }
            const data = await response.data
            resRequest(data);
            // console.log("Logged from use-http",data)
        }catch (e:any) {
            setError(e.message || 'Something went wrong')
        }
        setIsLoading(false)
    },[])
    return {
        isLoading: isLoading,
        error: error,
        sendRequest: sendRequest
    }
}

export default useHttp
