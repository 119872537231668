import React from "react";
import CardModel from "../models/cardModel";
import "./card.css";

const Card: React.FC<{ card: CardModel[] }> = (props) => {
  return (
    <>
      {props.card.map((item, index) => (
        <div
          key={index}
          className={"col-md-3 col-sm-4" + item.classes.vertical}
        >
          <div className={`card d-block rounded-lg shadow-9 
          animate__animated animate__bounceInUp animate__delay-3s ${index !== 0 ? "card-course": ""} `}>
            <div className={item.classes.bg + " card-body"}>
              <img
                className={item.classes.bg + " image-course mt-4"}
                src={item.img}
                alt="course"
              />
              <h5
                className={
                  item.classes.title +
                  " card-title lead-4 font-weight-bold mt-2"
                }
              >
                {item.title}
              </h5>
              <p className={item.classes.detail}>{item.detail}</p>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default Card;
