import SliderImg from "../components/sliderImg";
import sliderImgData from "../components/silderImgData";

const Gallery = () => {
  return (
    <div className="bg-dark mt-10 py-7">
      <div
        className={"slider-center-focused"}
        data-provide={"slider"}
        data-center-mode={"true"}
        data-center-padding={"20%"}
        data-autoplay="true"
        data-autoplay-speed="3600"
        data-arrows="true"
      >
        <SliderImg img={sliderImgData} />
      </div>
    </div>
  );
};

export default Gallery;
