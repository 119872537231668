import React from "react";
import feedbackModel from "../models/feedbackModel";

const feedbackCard: React.FC<{ feedback: feedbackModel[] }> = (props) => {
  return (
    <div className={"row gap-y"}>
      {props.feedback.map((feed, index) => (
        <div key={index} className="col-md-6">
          <div className={"card shadow-3 " + feed.classes.bg}>
            <div className="card-title text-danger pt-5">
              <h1 className={"ml-5 text-danger"}>
                <span
                  className={
                    "iconbox iconbox-outline iconbox-xl mr-4 " +
                    feed.classes.borderIcon
                  }
                >
                  <i className="fa fa-quote-left fa-1x text-danger" />
                </span>
                {feed.name}
              </h1>
              <small className={"ml-9 text-danger mt-0"}>{feed.grade}</small>
            </div>
            <div
              className={
                "card-body px-6 ml-8 mr-2 " + feed.classes.textCardBody
              }
            >
              <p className="mb-5">{feed.feedbackText}</p>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default feedbackCard;
