class CardModel {
  title: string;
  img: string;
  detail: string;
  classes: { vertical: string; bg: string; title: string; detail: string };

  constructor(
    cardTitle: string,
    cardImg: string,
    cardDetail: string,
    cardClasses: {
      textTitle: string;
      bg: string;
      vertical: string;
      textDetail: string;
    }
  ) {
    this.title = cardTitle;
    this.img = cardImg;
    this.detail = cardDetail;
    this.classes = {
      title: cardClasses.textTitle,
      bg: cardClasses.bg,
      vertical: cardClasses.vertical,
      detail: cardClasses.textDetail,
    };
  }
}

export default CardModel;
