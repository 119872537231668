import "./course.css";
import Card from "../components/Card";
import cardInfo from "../components/cardInfo";
import { HashLink as Link } from "react-router-hash-link";

const Courses = () => {
  return (
    <section className="section">
      <div className="container">
        <header className="section-header">
          <h4 id={"tour"} className={"text-danger letter-spacing-0 font-size-15"}>
            What are we out for
          </h4>
          <p className="lead-1 text-dark font-size-6 font-weight-bolder">
            Initiating students and professionals in the technical field, to
            enable them to design and carry out projects based on new
            technologies such as IoT, AI, NoSQL database, Big Data through the
            collaborative economy and sell their products through digital
            marketing. Open Technology then offers practical and open training
            in a short time, using the best possible strategies to push learners
            to understand and implement these technologies.
          </p>
        </header>

        <div className="row gap-y">
          <div className="w-100" />
          <Card card={cardInfo} />
        </div>
      </div>

      <div className="row no-gutters">
        <div
          className="col-md-4 bg-img"
          style={{
            backgroundImage: "url(./assets/img/previous/DSC_7869.png)",
            minHeight: "300px",
          }}
        />

        <div className="col-md-8 p-6 p-md-8 bg-dark ">
          <h1 className={"text-white"}>How we do that?</h1>
          <p className={"lead text-primary pt-2 "}>
            Through our training courses and our initiatives,
            we promote the development of new technologies for students,
            companies and third parties,
            and we equip ourselves with strong human and material potential for research and development.
          </p>
          <Link
            className="btn btn-danger letter-spacing-0 mt-5"
            to={"/#subscribe"}
          >
            Subscribe
          </Link>
        </div>
      </div>
    </section>
  );
};

export default Courses;
