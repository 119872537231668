import { useInput } from "../hooks/use-input";
import "animate.css";
import ThemeData from "../components/themeData";
import React, { useState } from "react";
import useHttp from "../hooks/use-http";
import urlAPI from "../helper";

const Subscribe = () => {
  const [a, setA] = useState("");
  const [checked, setChecked] = useState<Array<string>>([]);
  const [messageRes, setMessageRes] = useState("");
  const { sendRequest } = useHttp();

  const {
    value: enteredInput,
    isValid: enteredInputValid,
    valueChangeHandler: inputChangeHandler,
    reset: resetInput,
  } = useInput((value: string) => value.trim() !== "");

  let formValid = false;
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [formSend, setFormSend] = useState(true);

  const clickedButton = () => {
    setFormSubmitted(true);
  };

  if (enteredInputValid && checked.length === 3) {
    formValid = true;
  }

  const FormSubmit = (e: any) => {
    e.preventDefault();
    if (!enteredInputValid && checked.length === 0) {
      return;
    }
    setFormSend(true);
    sendRequest(
      {
        method: "POST",
        url: urlAPI + "subscription",
        headers: { "content-type": "application/json" },
        data: {
          firstname: enteredInput.fName,
          lastname: enteredInput.lName,
          email: enteredInput.email,
          school: enteredInput.school,
          speciality: enteredInput.speciality,
          portable: enteredInput.portable,
          course: checked[0] + " " + checked[1] + " " + checked[2],
        },
      },
      (subscription: any) => {
        setMessageRes(subscription.message);
        console.log(subscription);
      }
    ).then((r) => {
      console.log("logged: " + r);
    });
    resetInput();
    setChecked([]);
    setTimeout(() => {
      setFormSend(false);
    }, 5000);
  };

  const nameInputClasses = "form-control border border-dark rounded-md";

  return (
    <div id={"subscribe"} className="container ">
      <h3 className={"text-center display-3 mt-8 mb-4 animate__animated animate__pulse animate__infinite infinite"}>Join us by filling in the form below</h3>
      <div className="card border border-dark rounded-xl p-7 shadow-9 ">
        <div className="card-header alert alert-warning mt-4 mb-4" role="alert">
           If you don't receive the mail after your subscription, <b>Please retry</b>
        </div>
        {formSubmitted && formSend && messageRes === "Success" && (
          <div className="card-header alert alert-success mb-4 mt-2" role="alert">
            <b>successful</b> registration, please check your email
          </div>
        )}
        {formSubmitted && formSend && (messageRes !== "Success" || "") && (
          <div className="card-header alert alert-danger mb-4 mt-2" role="alert">
            <b>{messageRes}</b>
          </div>
        )}

        <form onSubmit={FormSubmit}>
          <div className="form-row">
            <div className="col">
              <input
                name={"fName"}
                type="text"
                className={nameInputClasses}
                placeholder="First name"
                // onBlur={nameBlurHandler}
                onChange={inputChangeHandler}
                value={enteredInput.fName}
              />
            </div>
            <div className="col">
              <input
                name={"lName"}
                type="text"
                className={nameInputClasses}
                placeholder="Last name"
                value={enteredInput.lName}
                // onBlur={nameBlurHandler}
                onChange={inputChangeHandler}
              />
            </div>
          </div>
          <div className="form-group">
            <input
              name={"email"}
              className={nameInputClasses + " mt-4"}
              type="text"
              placeholder={"Email"}
              value={enteredInput.email}
              onChange={inputChangeHandler}
              // onBlur={emailBlurHandler}
            />
          </div>
          <div className="form-row">
            <div className="col mb-3">
              <input
                name={"school"}
                className={nameInputClasses}
                placeholder={"Enter your school"}
                type="text"
                value={enteredInput.school}
                onChange={inputChangeHandler}
                // onBlur={schoolBlurHandler}
              />
            </div>
            <div className="col-sm-4 col-lg-4">
              <input
                name={"speciality"}
                className={nameInputClasses}
                placeholder={"Enter your Speciality"}
                type="text"
                value={enteredInput.speciality}
                onChange={inputChangeHandler}
                // onBlur={specialityBlurHandler}
              />
            </div>
          </div>
          <div className="form-group">
            <input
              name={"portable"}
              className={nameInputClasses + " mt-2"}
              type="text"
              placeholder={"Phone: ex: 690000000"}
              value={enteredInput.portable}
              onChange={inputChangeHandler}
            />
          </div>
          <p className={"text-dark font-weight-bold mt-2"}>
            Choose theme( <span>3 max</span>)
          </p>
          <div className="form-row">
            {ThemeData.map((item, index) => (
              <div key={index} className="col-sm col-lg-6">
                <input
                  className={"mr-2"}
                  id={"input-" + index}
                  type="checkbox"
                  checked={checked.indexOf(item.theme) >= 0}
                  disabled={
                    checked.length === 3 && checked.indexOf(item.theme) < 0
                  }
                  value={item.theme}
                  onChange={() => {
                    const newChosen = checked;
                    const ind = newChosen.indexOf(item.theme);
                    if (ind >= 0) {
                      newChosen.splice(ind, 1);
                      // console.log(newChosen);
                      setChecked(newChosen);
                    } else {
                      if (newChosen.length === 3) {
                        return;
                      }
                      newChosen.push(item.theme);
                      // console.log(newChosen);
                      setChecked(newChosen);
                      console.log(a);
                    }
                    setTimeout(function () {
                      setA(Math.random() + "");
                    }, 0);
                  }}
                />
                <label htmlFor={"input-" + index} className={"text-dark"}>
                  {item.theme}
                </label>
              </div>
            ))}
          </div>

          <div className="text-center mt-5">
            <button
              onClick={clickedButton}
              className="btn btn-danger btn-lg font-weight-bold rounded-lg"
              disabled={!formValid}
            >
              Subscribe
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Subscribe;
