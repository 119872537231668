import React from "react";
import sliderModel from "../models/sliderModel";
import "../UI/gallery.css"

const SliderImg: React.FC<{ img: sliderModel[] }> = (props) => {
  return (
    <>
      {props.img.map((img, index) => (
        <div key={index + img.alt} className="carousel-item p-2">
          <img className={"d-block w-100 carousel-gallery"} src={img.img} alt={img.alt} />
        </div>
      ))}
    </>
  );
};

export default SliderImg;
