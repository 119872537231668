import feedbackModel from "../models/feedbackModel";
import FeedbackModel from "../models/feedbackModel";

const feedbackInfo = [
  new feedbackModel(
    "Eto Samuelle Marie A",
    "engineering student",
    "My desire to learn about new technologies and my passion for ICTs led me to this introductory course in new technologies, open technologies. After completing it, I can only testify to the seriousness and structure of the course.\n" +
      "The practical nature of the course is what makes it so meaningful. Of course, there's room for improvement on a number of fronts, but what Inchtech's is currently offering through this initiative is just Wow.\n" +
      "\n",
    {
      borderIcon: "border-glass-2",
      textCardBody: "text-white",
      bg: "bg-dark",
    }
  ),
  new FeedbackModel(
    "EMMANUEL",
    "Physician",
    "I took up training in networks, telecommunications and systems after " +
      "my degree in physics at DSCHANG University." +
      " Many of the technical terms used in these technologies were still mythical to me. " +
      "But thanks to Inchtech's introductory training in new technologies, " +
      "I've been able to demystify a whole range of concerns. I can talk about technologies now, " +
      "clearly defining the terms I'm using, the purposes, functions and applications. " +
      "I can't wait to start practicing everything I've learned.",
    {
      borderIcon: "border-secondary",
      textCardBody: "",
      bg: "",
    }
  ),
];

export default feedbackInfo;
