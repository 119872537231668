class sliderModel {
  img: string;
  alt: string;
  constructor(img: string, alt: string) {
    this.img = img;
    this.alt = alt;
  }
}

export default sliderModel;
