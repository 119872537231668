const Contact = () => {
  return (
    <div className=" text-white bg-dark mt-10 py-7">
      <h2 className={"text-center font-weight-bold"}>
        Need help? Call our team (+237) 656 372 097 | (+237) 675 944 274
        <i className={"fa fa-phone mr-2 ml-2"} />
        <i className="fa fa-whatsapp ml-1" />
      </h2>
      <div className="social text-center">
        <a
          target={"_blank"}
          className="social-facebook mr-1"
          href={"https://www.facebook.com/inchtechs/"} rel="noreferrer"
        >
          <i className="fa fa-facebook fa-2x" />
        </a>
        <a
          target={"_blank"}
          className="social-twitter mr-2"
          href={"https://twitter.com/_InchTechs?t=2rk_UuPExC4JU70Ic097cw&s=09"} rel="noreferrer"
        >
          <i className="fa fa-twitter fa-2x" />
        </a>
        <a
          target={"_blank"}
          className="social-instagram mr-2"
          href="https://www.instagram.com/invites/contact/?i=s2hfe8c2fvqv&utm_content=7qjhwhu
" rel="noreferrer"
        >
          <i className="fa fa-instagram fa-2x" />
        </a>
        <a
          target={"_blank"}
          className="social-linkedin mr-2"
          href={"https://www.linkedin.com/company/inchtechs-sarl"} rel="noreferrer"
        >
          <i className="fa fa-linkedin fa-2x" />
        </a>
        <a
          target={"_blank"}
          className="social-youtube"
          href={"https://youtube.com/channel/UCs0QiaFyPDFFCYAr8doNJvw"} rel="noreferrer"
        >
          <i className="fa fa-youtube fa-2x" />
        </a>
      </div>
    </div>
  );
};

export default Contact;
