import { HashLink as Link } from "react-router-hash-link";

const Header = () => {
  return (
    <header
      className="header"
      style={{ backgroundImage: "url(./assets/img/cover-ot-5.webp)" }}
      data-overlay={7}
    >
      <div className="container">
        <div className="row align-items-center py-9">
          <div className="col-md-6">
            <br />
            <br />
            <h1
              className="display-5 text-white font-weight-bold"
              // data-font={"regular:700"}
            >
              Learn, create and have fun with technology
            </h1>
            <br />
            <br />

            <p className={"gap-xy"}>
              {/*<Link></Link>*/}
              <Link
                className="btn btn-danger letter-spacing-1"
                smooth
                to={"#subscribe"}
              >
                Subscribe Now
              </Link>

              <Link className="btn btn-outline-light btn-danger letter-spacing-1" smooth to={"#tour"}>
                Take a tour
              </Link>
            </p>

            <br />
            <br />
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
