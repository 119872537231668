import Courses from "./Courses";
import Feedback from "./Feedback";
import Gallery from "./Gallery";
import Footer from "./Footer";
import Header from "./Header";
import NavBar from "./NavBar";
import Contact from "./Contact";
import Subscribe from "./Subscribe";

const Home = () => {
  return (
    <>
      <NavBar />
      <Header />
      <Courses />
      <Feedback />
      <Gallery />
      <Subscribe />
      <Contact />
      <Footer />
    </>
  );
};

export default Home;
