import "./navbar.css";
import { HashLink as Link } from "react-router-hash-link";

const NavBar = () => {
  return (
    <nav
      className="navbar navbar-expand-lg navbar-light navbar-stick-dark"
      data-navbar="sticky"
    >
      <div className="container">
        <div className="row">
          <img
            className="logo-light logo-svg ml-2 mt-2"
            src={"assets/img/logo/open_logo_main.svg"}
            alt="logo"
          />
        </div>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <Link
          className="btn btn-sm btn-outline-light
          d-none d-lg-inline-block mr-2 animate__animated animate__shakeY animate__delay-3s"
          smooth
          to={"#subscribe"}
        >
          Subscribe
        </Link>
      </div>
    </nav>
  );
};
export default NavBar;
