import sliderModel from "../models/sliderModel";

const sliderImgData = [
  new sliderModel("assets/img/previous/DSC_7869.jpg", "img-slider"),
  new sliderModel("assets/img/previous/DSC_7869.png", "img-slider"),
  new sliderModel("assets/img/previous/DSC_7877.jpg", "img-slider"),

  new sliderModel("assets/img/previous/DSC_0005.jpg", "img-slider"),
  new sliderModel("assets/img/previous/DSC_0008.jpg", "img-slider"),
  new sliderModel("assets/img/previous/DSC_0009.jpg", "img-slider"),
  new sliderModel("assets/img/previous/DSC_0106.jpg", "img-slider"),
  new sliderModel("assets/img/previous/DSC_0119.jpg", "img-slider"),
  new sliderModel("assets/img/previous/DSC_0128.jpg", "img-slider"),
  new sliderModel("assets/img/previous/DSC_0153.jpg", "img-slider"),
  new sliderModel("assets/img/previous/DSC_0169.jpg", "img-slider"),
  new sliderModel("assets/img/previous/DSC_7785.png", "img-slider"),
  new sliderModel("assets/img/previous/DSC_7802.jpg", "img-slider"),
  new sliderModel("assets/img/previous/IMG_0171.jpg", "img-slider"),
  new sliderModel("assets/img/previous/IMG_0179.jpg", "img-slider"),
  new sliderModel("assets/img/previous/IMG_0181.jpg", "img-slider"),
  new sliderModel("assets/img/previous/IMG_0185.jpg", "img-slider"),
];

export default sliderImgData;
