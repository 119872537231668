import cardModel from "../models/cardModel";

const cardInfo = [
  new cardModel(
    "AI/ML",
    "assets/img/Icon awesome-brain.png",
    "Your employees can work much more efficiently if they know what the plan for today is",
    {
      vertical: "py-8",
      bg: "bg-dark",
      textTitle: "text-danger",
      textDetail: "text-white",
    }
  ),
  new cardModel(
    "Internet Of Things",
    "assets/img/Icon awesome-robot.png",
    "Track your sales plan in real time. We build great technology in-house, and put it to work for you.",
    {
      vertical: "",
      bg: "",
      textTitle: "text-warning",
      textDetail: "",
    }
  ),
  new cardModel(
    "Data Analytics",
    "assets/img/Icon ionic-md-analytics.png",
    "The system gives you the full visibility and control of your business that will help you\n" +
      "                      drive new sales and enhance profitability.",
    {
      vertical: "py-8",
      bg: "",
      textTitle: "text-warning",
      textDetail: "",
    }
  ),
  new cardModel(
    "Computer Security",
    "assets/img/Icon material-security.png",
    "From now, you can focus your time on solving customer problems. Mekashron\n" +
      "                      automatically creates cases from phone calls,\n" +
      "                      email, chat, and the customer portal.",
    {
      vertical: "",
      bg: "",
      textTitle: "text-warning",
      textDetail: "",
    }
  ),
];

export default cardInfo;
