class FeedbackModel {
  name: string;
  grade: string;
  feedbackText: string;
  classes: {
    borderIcon: string;
    textCardBody: string;
    bg: string;
  };
  constructor(
    name: string,
    grade: string,
    feedbackText: string,
    classes: { borderIcon: string; textCardBody: string; bg: string }
  ) {
    this.name = name;
    this.grade = grade;
    this.feedbackText = feedbackText;
    this.classes = {
      borderIcon: classes.borderIcon,
      textCardBody: classes.textCardBody,
      bg: classes.bg,
    };
  }
}

export default FeedbackModel;
